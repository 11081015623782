import { useEffect } from 'react';


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/owl.carousel.min.js';
import ListedProjectsItems from './ListedProjectsItems';

function ListedProjectSlider(props) {
 

      
    const options = {
        items: 3,
        merge: true,
        loop: false,
        video: true,
        lazyLoad: true,
        nav: true,
        dots: false,
        margin: 19,
        autoplay: true, // Added autoplay option
        responsive: {
            0: {             
                items: 1, 
                nav: false,
                dots: true                      
            },
            600: {
                items: 2,
                nav: false,
                dots: true                            
            },
            1000: {
                items: props.desktop,
                dots: false
            }
        }
 
    };
  return (
    <>
       
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col-sm">
                    <h2 className="font-30 font-400 mb-1 project-title">Featured  <span className="font-500">{props.title}</span></h2>
                    <p className="text-gray-41 font-18 font-500 mb-0 projects-subtitle">{props.subtitle} </p>
                </div>
                <div className="col-sm-auto">
                    <a href="javascript:;" className="btn btn-green w-auto mt-2 mt-sm-3 height-40 d-inline-flex text-white font-14 text-decoration-none job_internship_btn" data-aos="fade-right" data-aos-delay="300"><span>View more</span></a>
                </div>
            </div>

            <OwlCarousel className="owl-carousel owl-theme showcaseCarousel w-100" id={`${props.title}carousel`} {...options}>
                <ListedProjectsItems/>
            </OwlCarousel>            
     
    </>
  )
}

export default ListedProjectSlider;
