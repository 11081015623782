import React from 'react';
import { useNavigate } from 'react-router-dom';
import QuillEditor from '../QuillEditor.tsx';

function AddUploadDocuments() {

    const navigate = useNavigate(); // Initialize useNavigate hook
    
      // Handle the button click event
      const handleClick = () => {
        navigate('/post-your-property-price-detail'); // Navigate to the /rice-details page
      };

  return (
    <>
      <div className='row g-4'>
                    <div className="col-12">
                        <a className='font-24 mb-0 text-start title-link' href='/post-your-property-detail'><i className="bi bi-chevron-left me-2"></i>Add Property Details</a>
                    </div>
                    <div className='col-12'>
                        <label  className="form-label">Upload Images</label>
                        <div className='uploadwrap'>
                            <input type="file" multiple />
                            <div className='d-flex align-items-center justify-content-center'>
                                <i className="bi bi-cloud-arrow-up-fill me-2"></i> <span>Drag and Drop Images here</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <label  className="form-label">Upload Videos</label>
                        <div className='uploadwrap'>
                            <input type="file" multiple />
                            <div className='d-flex align-items-center justify-content-center'>
                                <i className="bi bi-cloud-arrow-up-fill me-2"></i> <span>Drag and Drop Videos here</span>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <label  className="form-label">Upload Documents</label>
                        <div className='uploadwrap'>
                            <input type="file" multiple />
                            <div className='d-flex align-items-center justify-content-center'>
                                <i className="bi bi-cloud-arrow-up-fill me-2"></i> <span>Drag and Drop Documents here</span>
                            </div>
                        </div>
                        <button className='btn btn-addmore mt-2'><i className="fa-solid fa-plus"></i> Add More</button>
                    </div>

                    <div className='col-12'>
                        <label  className="form-label">Description</label>
                        <QuillEditor/>
                    </div>

                    <div className='col-12'>
                        <label  className="form-label">Features</label>
                        <QuillEditor/>
                    </div>

                    <div className="col-12">
                        <div className="form-check custom-check">
                            <input className="form-check-input" type="checkbox" value="" id="Sharelistinginformationwithagents"/>
                            <label className="form-check-label" htmlFor="Sharelistinginformationwithagents">
                                Share listing information with agents
                            </label>
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn btn-big-green" type="submit" onClick={handleClick}>Next. add property details</button>
                    </div>

                </div>
    </>
  )
}

export default AddUploadDocuments;
